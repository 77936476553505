import React from 'react';
import s from "../SalePage.module.scss";
import a from "../Form.module.scss";

const Footer1 = () => {
    return (
        <div className={s.footer}>
            <div className={s.Contacts}>
                <p>Контакты:</p>
                <a href="mailto:info@milanapetrova.com" target="_blank">E-mail: info@milanapetrova.com</a>
                <a href="https://t.me/pashastayhome1" target="_blank">Онлайн-менеджер в Телеграм</a>
            </div>

            <div className={s.docs}>
                <a href="https://docs.google.com/document/d/15kN3LLS7bSILgrHK48ebNE1qwHdRe5NL/edit" target="_blank">
                    Политика обработки персональных данных
                </a>
                <a href="/оферта ИП Петров.pdf" download>Публичная оферта ИП Петров</a>
                <a href="https://docs.google.com/document/d/1rmTGQ_SJAsZ7CnswKfVvhPKjz3ZAppKn/edit" target="_blank">Согласие на обработку персональных данных</a>
            </div>

            <div className={s.EP}>
                <p>ИП Петров Андрей Вадимович <br/>
                    ИНН 401703366202 <br/>
                    ОГРНИП 318402700018650</p>
                <p>
                    «ТЕЛЕГРАМАТИКА» ООО <br/>
                    ИНН 02896366
                </p>
            </div>

        </div>
    );
};

export default Footer1;
