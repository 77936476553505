import React from 'react';
import {NavLink} from "react-router-dom";

const Error = () => {
    return (
        <div className='SuccessWrapper'>
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="40" fill="red" />
                <line x1="30" y1="30" x2="70" y2="70" stroke="white" stroke-width="10" />
                <line x1="30" y1="70" x2="70" y2="30" stroke="white" stroke-width="10" />
            </svg>

            <h2 style={{ color: '#007BFF' }}>Ошибка оплаты!</h2>
            <NavLink to="/">
                <button>Попробовать еще раз</button>
            </NavLink>
        </div>
    );
};

export default Error;
